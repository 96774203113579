import React from "react";
import styles from '../css/privacePolicy.module.scss'
const PrivacePolicy = () => {
  return (
    <div className={styles.privacy_policy}>
      <h3 className={styles.title}>隐私政策</h3>

      <p>发布日期：2024年8月30日</p>
      <p>更新日期：2024年8月30日</p>
      <p>生效日期：2024年8月30日</p>
      <p>公司主体：北京云启博远科技有限公司</p>

      <h4>本隐私政策将帮助您了解以下主要政策：</h4>

      <p>
        1）为了保障产品的核心功能以及其他功能正常运行，我们会收集您的部分必要信息；
      </p>

      <p>
        2)
        我们不会向第三方共享、提供、转让或者从第三方获取您的个人信息，除非经过您的同意；
      </p>

      <p>
        3）我们将努力采取合理的安全措施来保护您的个人信息。特别的，我们将采用行业内通行的方式及尽最大的商业努力来保护您个人敏感信息的安全；
      </p>

      <p>4）您访问、更正、删除个人信息、撤回同意授权，以及投诉举报的方式。</p>

      <p>
        如您不同意本隐私政策并开始使用，我们将仅收集维持“云启看图”基础功能所必需的信息，这将导致我们无法为您提供完整的产品和服务，但我们同样会采用去标识化、加密等措施来保护这些信息。当您再次使用时，为实现履行合同与服务所必需，将视为您接受和认可我们按照本政策对您的相关信息进行处理。
      </p>

      <p>
        我们非常重视用户个人信息的保护，并且将以勤勉和审慎的义务对待这些信息。您在下载、安装、开启、浏览、使用（以下统称“使用”）“云启看图”及相关服务时，我们将按照本隐私政策收集、保存、使用、共享、披露及保护您的个人信息。我们希望通过本隐私政策向您介绍我们对您个人信息的处理方式，因此我们建议您认真完整地阅读本隐私政策的所有条款。其中，与您信息及权利相关的内容将以加粗的形式提示您注意，请您重点阅读以下内容：
      </p>

      <h4>一、我们如何收集和使用个人信息</h4>

      <h4>二、我们对Cookie和同类技术的使用</h4>

      <h4>三、我们如何存储个人信息</h4>

      <h4>四、我们如何共享、转让、公开披露个人信息</h4>

      <h4>五、我们如何保护个人信息的安全</h4>

      <h4>六、您的权利</h4>

      <h4>七、未成年人使用条款</h4>

      <h4>八、隐私政策的修订和通知</h4>

      <h4>九、适用范围</h4>

      <h4>十、如何联系我们</h4>

      <h4>十一、其他</h4>

      <h4>一、我们如何收集和使用个人信息</h4>

      <p>
        在您使用“云启看图”及服务的过程中，我们将根据合法、正当、必要的原则，收集信息。我们收集或请您提供的信息将用于：
      </p>

      <p>1.保障产品的基础正常运行；</p>

      <p>2.实现各项功能和服务；</p>

      <p>3.优化、改善产品和服务；</p>

      <p>4.保障产品、服务以及用户使用安全；</p>

      <p>5.向您提供广告；</p>

      <p>6.遵循法律法规与国家标准的规定；</p>

      <p>（一）我们主动收集与使用的个人信息</p>
      <p>
        <b>
          请注意，您在打开阅读本隐私政策或用户协议的时候，系统在加载本网页（webview加载网页时）可能会存在使用您的wifi、获取运行中的进程信息、设备应用列表或状态行为等相关信息，请您悉知，该行为并非我们产品主动行为，同时也不会收集您的任何信息，我们仅会在您确认同意隐私政策后才会开始使用和收集信息。
        </b>
      </p>

      <p>
        我们会按照如下方式收集您在使用服务时主动提供的，以及您在使用功能或接受服务过程中产生的信息：
      </p>

      <p>1.保障“云启看图”及服务的正常运行</p>

      <p>
        当您使用“云启看图”及相关服务时，为了保障软件与服务的正常运行，我们会收集您的硬件型号、操作系统版本号、网络设备硬件地址（MAC）、IP地址、软件版本号、网络接入方式及类型、操作日志等信息。请您了解，这些信息是我们提供服务和保障产品正常运行所必须收集的基本信息。
      </p>

      <p>2.为您提供看图功能或服务</p>

      <table className={styles.jurisdiction_cn}>
        <thead>
          <tr>
            <th>
              <strong>
                <span>序号</span>
              </strong>
            </th>
            <th>
              <strong>
                <span>权限名称</span>
              </strong>
            </th>
            <th>
              <strong>
                <span>获取方式</span>
              </strong>
            </th>
            <th>
              <strong>
                <span>获取该权限的目的</span>
              </strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>获取设备识别码和状态</td>
            <td>动态获取，用户主动授权</td>
            <td>
              1.用于登录注册时作为识别真实用户而非外挂软件、机器的依据。
              <br /> 2.用于广告SDK向您提供广告。
              <br /> 3.用于收集应用产生的Bug信息，以便我们更好的改进产品。
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>访问网络</td>
            <td>静默获取</td>
            <td>用于获取本应用《用户协议》、《隐私政策》。</td>
          </tr>
          <tr>
            <td>3</td>
            <td>读写存储权限</td>
            <td>动态获取， 用户主动授权</td>
            <td>
              用于数据缓存，页面缓存，优化页面中的展示效果，提升响应速度。
            </td>
          </tr>
        </tbody>
      </table>

      <p>3.使用其他第三方代码统计您的信息</p>

      <p>
        您在使用我们产品的过程中，为了减少Bug影响您的使用，以及根据您的行为习惯,更改交互提升使用体验，我们将上传行为信息及崩溃日志至百度移动统计平台，用于统计详细崩溃日志以及行为分析。
      </p>

      <p>4.保障产品、服务及用户使用安全</p>

      <p>
        为帮助我们更好地了解“云启看图”及相关服务的运行情况，以便确保运行与提供服务的安全，我们使用第三方统计工具记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、总体安装、使用情况、性能数据等信息。
      </p>
      <p>
        请您了解，我们收集、使用上述信息时进行了去标识化处理/匿名化处理，数据分析仅对应特定的、无法直接关联用户身份的编码。
      </p>

      <p>（一）收集、使用个人信息目的变更的处理</p>

      <p>
        请您了解，随着我们业务的发展，可能会对“云启看图”的功能和提供的服务有所调整变化。原则上，当新功能或服务与看图等场景相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。
      </p>

      <p>（二）依法豁免征得同意收集和使用的个人信息</p>

      <p>
        请您理解，在下列情形中，根据法律法规和/或相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：
      </p>

      <p>（1）与国家安全、国防安全直接相关的；</p>

      <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>

      <p>（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>

      <p>
        （4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>

      <p>（5）所收集的您的个人信息是您自行向社会公众公开的；</p>

      <p>
        （6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>

      <p>（7）根据您的要求签订和履行合同所必需的；</p>

      <p>（8）法律法规规定的其他情形。</p>

      <p>
        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，则在结合使用期间，这些信息将作为您的个人信息按照本隐私政策处理与保护。
      </p>

      <h4>二、对Cookie和同类技术的使用</h4>

      <p>
        Cookie
        和同类技术是互联网中的通用常用技术。当您使用“云启看图”及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie
        或匿名标识符，以收集和存储您访问、使用本产品时的信息。我们可能会设置认证与保障安全性的cookie
        或匿名标识符，保障产品与服务的安全、高效运转，帮助我们改进服务效率，提升登录和响应速度。使用此类技术也可以帮助您省去重复选择设置的步骤和流程（记录设置中的选项状态），帮助您获得更轻松的访问体验。
      </p>

      <h4>三、我们如何存储个人信息</h4>

      <p>（一）信息存储的地点</p>

      <p>
        我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于合法合规的位置。
      </p>

      <p>（二）存储期限</p>

      <p>
        我们仅在为提供“云启看图”及服务之目的所必需的期间内保留您的个人信息，超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
      </p>

      <h4>四、我们如何共享、转让、公开披露个人信息</h4>

      <p>（一）共享</p>

      <p>
        尊重用户个人隐私是我们的一项基本原则。除以下情形外，我们不会与任何公司、组织和个人分享你的个人信息：
      </p>

      <p>
        1、在获取明确同意的情况下共享：获得你的明确同意后，我们会与其他方共享你的个人信息。
      </p>

      <p>2、我们可能会按照司法机关或行政机关的要求，对外共享你的个人信息。</p>

      <p>
        3、与关联方共享：你的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求你的授权同意。
      </p>

      <p>
        4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享你的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享你的个人信息，并且只会共享提供服务所必要的个人信息，在你上网购买我们的产品时必须与物流公司共享你的信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>

      <p>（二）转让</p>

      <p>我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>

      <p>
        1、在获取明确同意的情况下转让：获得你的明确同意后，我们会向其他方转让你的个人信息。
      </p>

      <p>（三）公开披露</p>

      <p>我们仅会在以下情况下，公开披露你的个人信息：</p>

      <p>1、在您明确同意的披露方式下披露您所指定的个人信息</p>

      <p>
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。
      </p>

      <p>
        3、请您理解，在下列情形中，根据法律法规和/或相关国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意
      </p>

      <p>（1）与国家安全、国防安全直接相关的；</p>

      <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>

      <p>（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>

      <p>
        （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>

      <p>（5）您自行向社会公众公开的个人信息；</p>

      <p>
        （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>

      <h4>五、我们如何保护个人信息安全</h4>

      <p>
        （一）我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
      </p>

      <p>
        （二）我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
      </p>

      <p>
        （三）我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。
      </p>

      <p>
        （四）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全。
      </p>

      <p>
        （五）我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止该等安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以提供通知、邮件、信函、短信等形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
      </p>

      <p>
        （六）我们谨此特别提醒您，本隐私政策提供的个人信息保护措施仅适用于“云启看图”及相关服务。一旦您离开“云启看图”及相关服务，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在“云启看图”及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于“云启看图”的链接或引导。
      </p>

      <h4>六、您的权利</h4>

      <p>
        我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的访问、修改（更新或更正）、删除以及撤回授权同意的权利和注销账号的权利，以使您拥有充分的能力保障您的隐私和安全，您的意见会及时得到处理。
      </p>

      <p>（一）管理、撤回授权您的信息</p>

      <p>
        您可以通过关闭功能、在设备设置中更改应用程序权限等方式撤回您的同意，改变您授权我们继续收集个人信息的范围或撤回您的授权。请您理解，特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </p>

      <p>（二）访问隐私政策</p>

      <p>
        您可以在客户端内的“设置”-“隐私政策”查看本隐私政策的全部内容。
        <br />
        请您了解，本隐私政策中所述的相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的软件及相关服务为准。
      </p>

      <p>（三）停止运营向您告知权利</p>

      <p>
        如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
      </p>
      <p>（四）注销账户</p>
      <p>
        请注意，产品内若无登录支付模块，则没有用户账号相关内容信息，也无须注销账户，并且不会含有注销账户的功能模块本隐私政策载明的方式与我们联系以注销您的账户，我们将在验证您的身份后十五个工作日内删除您的账号。
        <br />
        请注意，产品内若无登录支付模块，则没有用户账号相关内容信息，也无须注销账户。
      </p>
      <p>微信公众号：”云启博远科技“</p>
      <p>客服邮箱：service@yunqiboyuan.com</p>
      <p>
        您注销上述账户的行为是不可逆的，我们将停止为您提供产品或服务，不再收集您的个人信息，并依据您的要求删除与您账户相关的个人信息或做匿名化处理，但法律法规另有规定或监管部门另有要求的除外。
      </p>
      <p>（五）投诉举报</p>
      <p>
        <b>
          您可以按照我们公示的制度进行投诉或举报。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以在“设置”-“客服中心”或“反馈和投诉”，进入反馈界面与我们联系。我们核查后会在15个工作日内反馈您的投诉与举报
        </b>
      </p>

      <h4>七、未成年人条款</h4>

      <p>
        若您是未满18
        周岁的未成年人，在使用“云启看图”及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。
      </p>

      <p>
        我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
      </p>

      <p>
        若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策公示的联系方式与我们联系。
      </p>

      <h4>八、隐私政策的修订和通知</h4>

      <p>
        （一）为了给您提供更好的服务，“云启看图”及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，该修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。但未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。
      </p>

      <p>
        （二）本隐私政策更新后，我们会在本应用程序客户端，
        <b>
          并在更新后的条款生效前以重新弹出提示框的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本，且必须经过您的重新同意后才能继续我们的服务。
        </b>
        如您继续使用我们的服务，表示同意接受修订后的本政策的内容，但是如果更新的内容需要采集音视频、联系方式、地理位置等个人敏感信息，仍会再次以显著方式征求您的同意。
      </p>

      <p>
        （三）
        <b>
          对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信、私信或在浏览页面做特别提示等方式，说明隐私政策有变更，请您重新认真阅读）
        </b>
        。
      </p>
      <p>
        <b>
          （四）为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本隐私政策或通过弹窗或页面提示等方式向您说明对应个人信息的收集目的、范围及使用方式，并在征得您明示同意后收集、使用您的个人信息。
        </b>
      </p>

      <p>本隐私政策所指的重大变更包括但不限于：</p>

      <p>
        1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；
      </p>

      <p>
        2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有权变更等；
      </p>

      <p>3.个人信息共享、转让或公开披露的主要对象发生变化；</p>

      <p>4.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>

      <p>
        5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。
      </p>

      <h4>九、适用范围</h4>

      <p>
        本隐私权政策适用于由北京云启博远科技有限公司及其关联方提供的所有服务，包括本客户端、网站等，不适用于有单独的隐私权政策且未纳入本隐私权政策的第三方产品或服务。
      </p>

      <p>本隐私权政策不适用于：</p>

      <p>
        1.其他第三方产品或服务，可能包括在使用中向您显示的产品或网站和广告内容或者“云启看图”服务中链接到的其他产品或网站；
      </p>

      <p>2.为“云启看图”服务进行广告宣传的其他第三方</p>

      <p>
        您使用这些第三方服务（包括您向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请您仔细阅读第三方的条款。请您妥善保护自己的个人信息，仅在必要的情况下向第三方提供。
      </p>

      <p>
        本隐私政策中所述的“云启看图”及相关服务有可能会根据您所使用的手机型号、系统版本、软件应用程序版本等因素而有所不同。最终的产品和服务以您所使用的“云启看图”及相关服务为准。
      </p>

      <h4>十、如何联系我们</h4>

      <p>
        1.如对本隐私政策内容有任何疑问、意见或建议，您可发送邮件至service@yunqiboyuan.com与我们联系。
      </p>

      <p>
        2.如果您认为我们违反法律法规规定或与您的约定收集、使用您的个人信息，您也可以通过发送电子邮件（service@yunqiboyuan.com）方式与我们取得联系，并要求删除您的相关个人信息。我们承诺将在15个工作日之内确认所涉问题，并由专人验证您的用户身份后及时予以回复与处理。
      </p>

      <h4>十一、其他</h4>

      <p>
        （一）本“隐私政策”中的标题仅为方便及阅读而设，并不影响本隐私政策中任何规定的含义或解释。
      </p>

      <p>（二）本“隐私政策”相关词语释义：</p>

      <p>
        1.
        关联方是指，指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被依法认定的方式。
      </p>

      <p>
        2.去标识化是指，通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
      </p>

      <p>
        3.匿名化是指，通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
      </p>

      <p>
        （三）本“隐私政策”的版权为北京云启博远科技有限公司，在法律允许的范围内，我们拥有解释和修改的权利。
      </p>
    </div>
  );
};

export default PrivacePolicy;
