import React from "react";
import styles from "../css/userAgreement.module.scss";
const UserAgreement = () => {
  return (
    <div className={styles.userAgreement}>
      <div>
        <h3 className={styles.title} style={{ textAlign: "center" }}>
          用户协议
        </h3>
        <div className={styles.content}>
          <p>公司主体：北京云启博远科技有限公司</p>
          北京云启博远科技有限公司（以下简称“云启博远”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。“用户”是指注册、登录、使用本服务的个人。本协议可由“云启博远”随时更新，更新后的协议条款一旦公布即代替原来的协议条款，用户可在本应用/网站查阅最新版协议条款。在“云启博远”修改协议条款后，如果用户不接受修改后的条款，请立即停止使用“云启博远”提供的服务，用户继续使用“云启博远”提供的服务将被视为接受修改后的协议。
        </div>
        <p className={styles.title}>一、服务内容</p>
        <div className={styles.content}>
          <p>
            1、本服务的具体内容由云启博远根据实际情况提供，云启博远可以对其提供的服务予以变更，且云启博远提供的服务内容可能随时变更；用户将会收到云启博远关于服务变更的通知。
          </p>
          <p>
            2、云启博远提供的服务包含免费服务与收费服务。用户可以通过支付宝付费购买收费服务，从而获得收费服务使用权限。对于收费服务，云启博远会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成第三方支付平台生成“支付已完成”的确认通知为准。
          </p>
        </div>
        <p className={styles.title}>二、用户个人信息保护</p>
        <div className={styles.content}>
          <p>
            1、用户个人信息包括：用户自行提供的用户个人信息，如注册时填写的手机号码，电子邮件等个人信息，使用服务时提供的共享信息等。
          </p>
          <p>
            2、用户在注册帐号或使用本服务的过程中，可能需要填写或提交一些必要的个人信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
          </p>
          <p>
            3、尊重用户个人信息的私有性是云启博远的一贯制度，云启博远将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者云启博远发现存在发生前述情形的可能时，云启博远将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与云启博远联系。
          </p>
          <p>
            4、云启博远未经用户同意不向任何第三方公开、
            透露用户个人隐私信息。但以下特定情形除外：
          </p>
          <p>
            (1)云启博远根据法律法规规定或有权机关的指示提供用户的个人隐私信息；
          </p>
          <p>
            (2)由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因云启博远原因导致的个人隐私信息的泄露；
          </p>
          <p>(3)用户自行向第三方公开其个人隐私信息；</p>
          <p>
            (4)用户与云启博远及合作单位之间就用户个人隐私信息的使用公开达成约定，云启博远因此向合作单位公开用户个人隐私信息；
          </p>
          <p>
            (5)任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；
          </p>
          <p>(6)用户个人信息已经经过处理无法识别特定个人且不能复原。</p>
          <p>5、用户同意云启博远可在以下事项中使用用户的个人信息：</p>
          <p>
            (1) 云启博远向用户及时发送重要通知，如软件更新、本协议条款的变更；
          </p>
          <p>
            (2)
            云启博远内部进行审计、数据分析和研究等，以改进云启博远的产品、服务和与用户之间的沟通；
          </p>
          <p>(3) 依本协议约定，云启博远管理、审查用户信息及进行处理措施；</p>
          <p>(4) 适用法律法规规定的其他事项。</p>
          <p>
            5、除上述事项外，如未取得用户事先同意，云启博远不会将用户个人隐私信息使用于任何其他用途。
          </p>
          <p>
            6、为了改善云启博远的技术和服务，向用户提供更好的服务体验，云启博远或可会自行收集使用或向第三方提供用户的非个人隐私信息。
          </p>
          <p>
            7、云启博远保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。
          </p>
          <p>
            8、云启博远十分注重保护用户的个人隐私，并制定了
            《隐私政策》，用户亦可以通过
            《隐私政策》，用户确认并同意使用云启博远提供的服务将被视为接受
            《隐私政策》。
          </p>
        </div>
        <p className={styles.title}>三、内容规范</p>
        <div className={styles.content}>
          <p>
            1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于帐号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用帐号或本服务所产生的内容。
          </p>
          <p>
            2、用户不得利用产品或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：
          </p>
          <p>(1) 反对宪法所确定的基本原则的；</p>
          <p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
          <p>(3) 损害国家荣誉和利益的；</p>
          <p>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
          <p>(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
          <p>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
          <p>
            (9)
            不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；
          </p>
          <p>
            (10)
            应国家法律法规，请勿上传“涉政、涉恐、涉密”等违法违规内容，产品会对您上传的内容进行审核，如有发现以上涉敏内容，我们会将该内容进行存储，并妥善保存这些文件，保留6个月。我们承诺该内容不会泄漏给第三方（除国家政府机构要求外）；
          </p>
          <p>(11) 含有法律、行政法规禁止的其他内容的信息。</p>
          <p>
            3、用户不得利用产品帐号或本服务制作、上载、复制、发布、传播如下干扰产品正常运营，以及侵犯其他用户或第三方合法权益的内容：
          </p>
          <p>(1)含有任何性或性暗示的；</p>
          <p>(2)含有辱骂、恐吓、威胁内容的；</p>
          <p>(3)含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
          <p>(4)涉及他人隐私、个人信息或资料的；</p>
          <p>(5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
          <p>
            (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
          </p>
        </div>
        <p className={styles.title}>四、使用规则</p>
        <div className={styles.content}>
          <p>
            1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表云启博远的观点、立场或政策，云启博远对此不承担任何责任。
          </p>
          <p>2、用户不得利用产品帐号或本服务进行如下行为：</p>
          <p>
            (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；
          </p>
          <p>(2) 强制、诱导其他用户关注、点击链接页面或分享信息的；</p>
          <p>(3) 虚构事实、隐瞒真相以误导、欺骗他人的；</p>
          <p>(4) 利用技术手段批量建立虚假帐号的；</p>
          <p>(5) 利用产品帐号或本服务从事任何违法犯罪活动的；</p>
          <p>
            (6)
            制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
          </p>
          <p>(7) 其他违反法律法规规定、侵犯其他用户合法权益</p>
          <p>
            3、用户须对利用产品帐号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与云启博远无关。如因此给云启博远或第三方造成损害的，用户应当依法予以赔偿。
          </p>
          <p>
            4、云启博远提供的服务中可能包括广告，用户同意在使用过程中显示云启博远和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，云启博远不承担任何责任。
          </p>
          <p>5、除非云启博远书面许可，用户不得从事下列任一行为：</p>
          <p>(1) 删除软件及其副本上关于著作权的信息；</p>
          <p>
            (2)
            对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；
          </p>
          <p>
            (3)
            对云启博远拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
          </p>
          <p>
            (4)
            对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经云启博远授权的第三方工具/服务接入软件和相关系统；
          </p>
          <p>
            (5)
            通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
          </p>
          <p>
            (6)
            通过非云启博远开发、授权的第三方软件、插件、外挂、系统，登录或使用云启博远软件及服务，或制作、发布、传播非云启博远开发、授权的第三方软件、插件、外挂、系统。
          </p>
        </div>
        <p className={styles.title}>五、数据储存</p>
        <div className={styles.content}>
          <p>1、云启博远不对用户在本服务中相关数据的删除或储存失败负责。</p>
          <p>
            2、云启博远可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。
          </p>
          <p>
            3、如用户停止使用本服务或本服务终止，云启博远可以从服务器上永久地删除用户的数据。本服务停止、终止后，云启博远没有义务向用户返还任何数据。
          </p>
        </div>
        <p className={styles.title}>六、知识产权声明</p>
        <div className={styles.content}>
          <p>
            1、除本服务中涉及的广告和第三方合作伙伴提供的内容资源的知识产权由相应广告商和第三方享有外，云启博远在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归云启博远所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。
          </p>
          <p>
            2、除另有特别声明外，云启博远提供本服务时所依托软件的著作权、专利权及其他知识产权均归云启博远所有。
          </p>
          <p>
            3、云启博远在本服务中所涉及的图形、文字或其组成，以及其他云启博远标志及产品、服务名称（以下统称“云启博远标识”），其著作权或商标权归云启博远所有。未经云启博远事先书面同意，用户不得将云启博远标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理云启博远标识的行为。
          </p>
          <p>
            4、上述及其他任何云启博远或相关广告商依法拥有的知识产权均受到法律保护，未经云启博远或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。
          </p>
          <p>
            5、用户在使用云启博远服务时发表上传的文字、图片、视频、音频、软件以及表演等信息，此部分信息的知识产权归用户，责任由用户承担。但用户的发表、上传行为视为对云启博远的授权，用户理解并同意授予云启博远及其关联公司全球范围内完全免费、不可撤销、独家、永久、可转授权和可再许可的权利，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利及邻接权利。云启博远可自行选择是否使用以及使用方式，包括但不限于将前述信息在云启博远旗下的服务平台上使用与传播，将上述信息再次编辑后使用，以及由云启博远授权给合作方使用、编辑与传播等。
          </p>
        </div>
        <p className={styles.title}>七、法律责任</p>
        <div className={styles.content}>
          <p>
            1、如果云启博远发现或收到他人举报或投诉用户违反本协议约定的，云启博远有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁
            、且不通知用户处理结果。
          </p>
          <p>
            2、用户理解并同意，云启博远有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
          </p>
          <p>
            3、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿云启博远与合作公司、关联公司，并使之免受损害。
          </p>
        </div>
        <p className={styles.title}>八、不可抗力及其他免责事由</p>
        <div className={styles.content}>
          <p>
            1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，云启博远将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，云启博远及合作单位在法律允许的范围内免责。
          </p>
          <p>
            2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。
          </p>
          <p>
            3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，云启博远不承担任何责任。
          </p>
          <p>
            4、用户理解并确认，云启博远需要定期或不定期地对软件或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，云启博远无需为此承担任何责任，但云启博远应事先进行通告。
          </p>
          <p>
            5、云启博远依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成云启博远的义务或承诺，担任何责任：
          </p>
          <p>(1)云启博远向用户免费提供的服务；</p>
          <p>(2)云启博远向用户赠送的任何产品或者服务。</p>
          <p>
            6、在任何情况下，云启博远均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用我司产品或本服务而遭受的利润损失，承担责任（即使云启博远已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，云启博远对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用云启博远提供的服务而支付给云启博远的费用。
          </p>
        </div>
        <p className={styles.title}>九、信息变更服务</p>
        <div className={styles.content}>
          <p>
            1、鉴于网络服务的特殊性，用户同意云启博远有权随时变更、中断或终止部分或全部的服务（包括收费服务）。云启博远变更、中断或终止的服务，云启博远应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，如果该用户已经向云启博远支付费用，云启博远应当按照该用户实际使用服务的情况扣除相应费用之后将剩余的费用退还用户。
          </p>
          <p>
            2、如发生下列任何一种情形，云启博远有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：
          </p>
          <p>
            (1)
            根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
          </p>
          <p>(2) 用户违反相关法律法规或本协议的约定；</p>
          <p>(3) 按照法律规定或有权机关的要求；</p>
          <p>(4) 出于安全的原因或其他必要的情形。</p>
        </div>
        <p className={styles.title}>十、其他</p>
        <div className={styles.content}>
          <p>
            1、云启博远郑重提醒用户注意本协议中免除云启博远责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。
          </p>
          <p>
            2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和云启博远之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交云启博远住所地有管辖权的人民法院管辖。
          </p>
          <p>
            3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
          </p>
          <p>
            4、由于互联网高速发展，您与云启博远签署的本协议列明的条款可能并不能完整罗列并覆盖您可以所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，隐私权政策、产品行为规范等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用产品软件服务，视为您同意上述补充协议。
          </p>
          <p>
            5、如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向“云启博远”举报或投诉，您可发送邮件至service@yunqiboyuan.com与我们联系，“云启博远”将依本协议约定进行处理。
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
