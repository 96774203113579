import React from 'react';
import './index.css'
import Routes from './router/index'
const App = () => {
    return (
        <Routes />
    );
}

export default App;
