import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
const domNode = document.getElementById("root");
const root = createRoot(domNode);
console.log('process',process.env.NODE_ENV);
if(process.env.NODE_ENV==='production'){
  console.log=()=>{}
}

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
