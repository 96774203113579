import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./images/logo.svg";
import piclogo from "./images/picture/piclogo.svg";
import img1 from "./images/img1.png";
import opt1 from "./images/picture/opt1.svg";
import opt2 from "./images/picture/opt2.svg";
import opt3 from "./images/picture/opt3.svg";
import opt4 from "./images/picture/opt4.svg";
import opt5 from "./images/picture/opt5.svg";
import section1 from "./images/picture/section1.png";
import section2 from "./images/picture/section2.png";
import section3 from "./images/picture/section3.png";
import section4 from "./images/picture/section4.png";
import section5 from "./images/picture/section5.png";
import styles from "./css/home.module.scss";
import Footer from "./Footer.jsx";
const yaml = require("js-yaml");
// https://tool.yunqiboyuan.com/upload/kantu/latest.yml
const baseUrl = "https://tool.yunqiboyuan.com/";
const timestamp = new Date().getTime();

const ymlUrl = `${baseUrl}upload/kantu/appversion/latest.yml?v=${timestamp}`;

const Home = () => {
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const [app_name, setApp_name] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    fetchAndParseYaml(ymlUrl);
  }, []);

  async function fetchAndParseYaml(url) {
    console.log("url", url);
    try {
      // 发送 HTTP GET 请求获取 YAML 文件内容
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.text();
      // 解析 YAML 内容
      const config = yaml.load(data);
      // 读取并打印 path 字段
      const filePath = config?.path;
      console.log("Path:", filePath);
      setDownloadUrl(`${baseUrl}upload/kantu/appversion/${filePath}`);
      setApp_name(filePath);
    } catch (e) {
      console.error("读取或解析 YAML 文件时出错:", e);
    }
  }

  const download = () => {
    console.log(" downloadUrl", downloadUrl);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = app_name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} title="logo" />
          <span onClick={() => handleNavigate("/")}>压缩工具</span>
          <span
            style={{ color: "#FF4338", borderColor: "#FF4338" }}
            onClick={() => handleNavigate("/homeImage")}
          >
            看图工具
          </span>
        </div>

        <div className={styles.nav}>
          <span onClick={() => handleNavigate("/about")}>关于我们</span>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.part1}>
          <div className={styles.titlecontent}>
            <img src={piclogo} alt="" />
            <span className={styles.title}>云启看图</span>
          </div>
          <div className={styles.desc}>
            一款能浏览和编辑各种格式图片的专业图片管理工具
          </div>
          <div className={styles.img1}>
            <img src={img1} alt="" />
          </div>
          <div className={styles.bigBtn} onClick={() => download()}>
            <span className={styles.title1}>立即下载 </span>
            <span className={styles.title2}>适用于WIN7-WIN11的Windows系统</span>
          </div>
          <div className={styles.descborder}>
            我们非常尊重用户隐私，我们不会收集用户的任何文件信息，我们完全遵循用户的隐私政策
          </div>
          <div className={styles.optlist}>
            <div
              className={`${styles.opt1}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt1} alt="" />
              <span>快速浏览</span>
            </div>
            <div
              className={`${styles.opt2}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt2} alt="" />
              <span>图片转PDF</span>
            </div>
            <div
              className={`${styles.opt3}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt3} alt="" />
              <span>格式转换</span>
            </div>
            <div
              className={`${styles.opt4}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt4} alt="" />
              <span>图片修改</span>
            </div>
            <div
              className={`${styles.opt5}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt5} alt="" />
              <span>图片压缩</span>
            </div>
          </div>
        </div>
        <div className={styles.part2}>
          <div
            className={`${styles.section1} ${styles.section}`}
            style={{ paddingLeft: "50px" }}
          >
            <div className={styles.left}>
              <div className={styles.word}>
                <div className={styles.card}>图片浏览</div>
                <div className={styles.title}>简约的操作方式</div>
                <div className={styles.desc}>
                  支持JPG、PNG、BMP等多种图片格式的浏览，并能够进行便捷的缩放、旋转等操作。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img
                src={section1}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
          </div>
          <div
            className={`${styles.section2} ${styles.section}`}
            style={{ paddingRight: "50px" }}
          >
            <div className={styles.left}>
              <img
                src={section2}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
            <div className={styles.right}>
              <div className={styles.word}>
                <div className={styles.card}>图片转PDF</div>
                <div className={styles.title}>支持多个图片转PDF</div>
                <div className={styles.desc}>
                  支持各种多张不同的格式图片转到一个PDF上进行使用，功能稳定、高效。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.section3} ${styles.section}`}
            style={{ paddingLeft: "50px" }}
          >
            <div className={styles.left}>
              <div className={styles.word}>
                <div className={styles.card}>图片格式转换</div>
                <div className={styles.title}>灵活的格式转换方式</div>
                <div className={styles.desc}>
                  支持将多个图片格式转换成JPG、PNG、BMP三种不同的类型，满足日常需求。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img
                src={section3}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
          </div>
          <div
            className={`${styles.section4} ${styles.section}`}
            style={{ paddingLeft: "50px" }}
          >
            <div className={styles.left}>
              <img
                src={section4}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
            <div className={styles.right}>
              <div className={styles.word}>
                <div className={styles.card}>图片修改</div>
                <div className={styles.title}>便捷修改图片尺寸或比例</div>
                <div className={styles.desc}>
                  支持对不同格式的图片进行大小尺寸或者按照等比例方式进行修改。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.section5} ${styles.section}`}
            style={{ paddingLeft: "50px" }}
          >
            <div className={styles.left}>
              <div className={styles.word}>
                <div className={styles.card}>图片压缩</div>
                <div className={styles.title}>将图片压缩成你需要的大小</div>
                <div className={styles.desc}>
                  支持将多个图片格式进行压缩，可以选择日常需要的大小进行使用。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img
                src={section5}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
          </div>
        </div>

        <div className={styles.part3}>
          <div className={styles.titlecontent}>
            <span className={styles.title}>云启看图</span>
          </div>
          <div className={styles.desc}>
            免费下载、绝无广告、安全可靠、放心下载！
          </div>

          <div className={styles.bigBtn} onClick={() => download()}>
            <span className={styles.title1}>立即下载 </span>
            <span className={styles.title2}>适用于WIN7-WIN11的Windows系统</span>
          </div>
          <div className={styles.descborder}>
            我们非常尊重用户隐私，我们不会收集用户的任何文件信息，我们完全遵循用户的隐私政策
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
